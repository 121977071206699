import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage,FormattedHTMLMessage } from "react-intl";
import Newsletter from "../Newsletter"
import { ROOT_URL, APP_URL } from "../../helper/Config";

const Footer = () => {
  let CurrentDate   =  new Date();
  //let copyRightDate = CurrentDate.getFullYear();
  const logoUrl  = APP_URL+"assets/images/LOGO4.png";
 

  return (
    <React.Fragment>
      <footer className="page_footer">
        <div className="container">
          <div className="row">
            <div
              className="footer col-md-4 text-center animate"
              data-animation="fadeInUp"
            >
              <div className="footer widget text-center">
              <img src={logoUrl} alt="PilorRh Solution" style={{height:"200px"}}/>
                {/* <h3 className="widget-title title-menu text-white">
                  <FormattedMessage
                    id="footer.widget1"
                    defaultMessage="MENU" 
                  />
                </h3> */}
                {/* <ul className="footer-menu">
                  <li>
                    <Link to="/youareacompany" className="">
                      <FormattedMessage
                        id="footer.professional"
                        defaultMessage="Individual"
                      />
                    </Link>
                  </li>
                
                  <li>
                  <Link to="/youareaconsultant" className="">
                     <FormattedMessage
                        id="footer.enterprise"
                        defaultMessage="Enterprise Solutions"
                      />
                    </Link>
                  </li>
                  <li className="menu1">
                  <Link to="/individual" className="">
                     <FormattedMessage
                        id="footer.individual"
                        defaultMessage="About Us"
                      />
                    </Link>
                  </li>
                  <li className="menu1">
                    <Link to="/about-section/story" className="">
                      <FormattedHTMLMessage
                        id="footer.Subscribe"
                        defaultMessage="HR Professional Tools"
                      />
                    </Link>
                  </li>
                   
                  <li className="menu1">
                    <Link to="/ourclient" className="">
                      <FormattedHTMLMessage
                        id="footer.15days"
                        defaultMessage="HR Professional Tools"
                      />
                    </Link>
                  </li>
                  {/* <li className="menu1">
                  <Link to="/FAQ" className="">
                     <FormattedMessage
                        id="footer.FAQ"
                        defaultMessage="About Us"
                      />
                    </Link>
                  </li> */}
                  {/* <li className="menu1">
                  <Link to="/about-us" className="">
                     <FormattedMessage
                        id="footer.aboutus"
                        defaultMessage="About Us"
                      />
                    </Link>
                  </li> */}
                  {/* <li className="border-bottom-0">
                    <Link to="/contact-us" className="">
                      <FormattedMessage
                        id="footer.contactus"
                        defaultMessage="Contact"
                      />
                    </Link>
                  </li>
                  <li className="menu1 border-bottom-0">
                    <Link to="/" className="">
                    </Link> */}
                  {/* </li>
                </ul>  */}
              </div>
            </div>
            <div
              className="footer  col-md-4 text-center animate"
              data-animation="fadeInUp"
            >
              <div className="text-center">
                <div className="header_logo_center footer-logo-ds">
                  {/* <Link to="/" className="logo">
                    <img src="assets/images/logo.png" alt="" />
                  </Link> */}
                  <h1 style={{color:'white'}} className="logo">PILOT RH®</h1>
                </div>
              </div>
              <div className="widget pt-20 text-white">
                  <FormattedMessage
                    id="footer.punchline"
                    defaultMessage="HR decision tools since 1999"
                  />
              </div>
              <div className="widget">
                <div className="media">               
                  4 rue des Fossés Saint-Jacques <br />75005 PARIS – France
                </div>
                <div className="media text-white font-weight-bold">+ 33 (0)9 83 200 015 </div>
                <div className="media text-center link">
                  <a className="text-white" href={"mailto:" +'contact@pilotrhsolutions.com'}>contact@pilotrh.com</a>
                </div>
              </div>
              <div className="author-social">
              <a
                  href="https://www.linkedin.com/in/olivier-colson-pilotrh"
                  className="fa fa-linkedin color-bg-icon rounded-icon"
                />
                <Link
                  to="/"
                  className="fa fa-facebook color-bg-icon rounded-icon"
                />
                <Link
                  to="/"
                  className="fa fa-twitter color-bg-icon rounded-icon"
                />
                <Link
                  to="/"
                  className="fa fa-google color-bg-icon rounded-icon"
                />
              </div>
            </div>
            <div
              className="footer col-md-4 text-center animate"
              data-animation="fadeInUp"
            >
              <div className="widget widget_mailchimp">
                <Newsletter/>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section className="page_copyright ">
        <div className="container">
          <div className="footer__bottom">
            <div className="footer__arrow-top"><a href="#"><i className="fa fa-chevron-up"></i></a></div>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="footer__copyright">
                  &copy; 
                        <FormattedMessage
                          id="footer.copyrights"
                          defaultMessage={`Copyright {year}, All rights Reserved 5W PILOT RH SOLUTIONS.`}
                          values={{year: CurrentDate.getFullYear()}}
                        />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer-nav">
                      <ul id="menu-footer-menu" className="menu">
                        <li id="menu-item-4876" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4876">
                          <Link to="/mentions-legales">
                            <FormattedMessage id="footer_legal_link" defaultMessage="Legal Notices" />
                          </Link>  
                        </li>
                        <li id="menu-item-3512" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3512">
                          <Link to="/conditions-generales">
                            <FormattedMessage id="footer_terms_link" defaultMessage="Conditions & Terms" />
                          </Link>
                        </li>
                        <li id="menu-item-4884" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4884">
                          <FormattedHTMLMessage id="footer_personal_data" defaultMessage="Personal Data" />
                        </li>
                      </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
       
      </section>
    </React.Fragment>
  );
};

export default Footer;
