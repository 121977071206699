import React, { Component } from "react";
import { Router, Route } from "react-router-dom";
import { history } from "../helper/History";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";

import message_en from "../language/en.json";
import message_fr from "../language/fr.json";

import AuthGuard from "./AuthGuard";
import Header from "./includes/Header";
import Home from "./Home";
import Register from "./Register";
import coachingOffer from "./coachingOffer";
import Hr from "./Hr";
import NewBuy from "./NewBuy";
import Enterprisesub from "./Enterprisesub";
import enterprisePrice from "./enterprisePrice";
import Individual from "./Individual";
import talentCompy from "./talentCompy";
import YouareaCompany from "./youAreaCompany";
import YouareaConsultant from "./YouareConsultant";
import recruitmentsource from "./recruitmentsource";
import recruitmentsourcing from "./recruitmentsourcing";
import Enterprise from "./Enterprise";
import FullSkillAssessment from "./FullSkillAssessment";
import EntrepreneurSkillAssessment from "./EntrepreneurSkillAssessment";
import SupportResources from "./SupportResources";
import SecureRecruitments  from "./SecureRecruitments";
import SourceResources  from "./SourceResources";


import Happiness from "./tests/Happiness";

import Result from "./tests/Result";
import Resultsale from "./tests/Resultsale";
import CreationResult from "./tests/CreationResult";
import Talent from "./tests/talent/Talent";
import Target from "./tests/target/Target";
import Sale from "./tests/Sale";
import Franchise from "./tests/Franchise";
import Payment from "./Payment";
import hraudit from "./hraudit";
import Buysubscription from "./Buysubscription";
import CreateLink from "./CreateLink";
import login from "./login";
import Coaches from "./Coaches";
//import Entrepreneur from "./tests/Entrepreneur";

import BussinessCreation from "./tests/BusinessCreation";
import Recruitment from "./tests/recruitment/Recruitment";
import RecruitmentResult from "./tests/recruitment/RecruitmentResult";
import CoachingCareer from "./tests/hr/career/CoachingCareer";
import CoachingCareerResult from "./tests/hr/career/CoachingCareerResult";
import Aboutus from "./Aboutus";
// import Story from "./story";
// import AboutValue from "./AboutValue";
import Partner from "./partner";
import AboutSection from "./AboutSection";
// import OurBenefits from "./OurBenefits";
// import GoodReasons from "./GoodReasons";
// import AboutUsTestimonials from "./AboutUsTestimonials";
// import AboutUsFAQ from "./AboutUsFAQ";
import Subscribe from "./Subscribe";
import ourClient from "./ourClient";
import FAQ from "./FAQ";
import Contactus from "./Contactus";
import PreRegistration from "./PreRegistration";
import PreSelection from "./PreSelection";
import ContactToCoach from "./ContactToCoach";
import ContactToHr from "./ContactToHr";
import Userdetails from "./Userdetails"
import ContactToEnterprise from "./ContactToEnterprise";
import Legals from "./Legals";
import TermsConditions from "./TermsConditions";
import PersonalData from "./PersonalData";
import Correlation from "./Correlation";
import DisplayCandidates from "./DisplayCandidates";
import JobProfile from "./JobProfile";
import PreServices from "./PreServices";


class App extends Component {
  render() {
    const messages = {
      fr: message_fr,
      en: message_en,
      testType: null
    };
    let { lang } = this.props;
    return (
      <IntlProvider locale={lang} messages={messages[lang]}>
        <Router history={history}>
          <React.Fragment>
            <Header />
            <Route path="/" exact component={Home} />
            <Route path="/Register/:id?" exact component={Register}/>
            <Route path="/login/:id?" exact component={login}/>
            <Route
              path="/happiness-link/:hr/:op?"
              exact
              component={AuthGuard(Happiness)}
            />
             <Route
              path="/happiness/:hr?"
              exact
              component={AuthGuard(Happiness)}
            />
            <Route path="/talent/:hr?" exact component={AuthGuard(Talent)} />
            <Route path="/target" exact component={AuthGuard(Target)} />
            <Route path="/hr" exact component={Hr} />
            <Route path="/individual" exact component={Individual} />
            <Route path="/recruitmentsourcing" exact component={recruitmentsourcing}/>
            <Route path="/hraudit" exact component={hraudit}/>
            <Route path="/coachingOffer" exact component={coachingOffer}/>
            <Route path="/NewBuy" exact component={NewBuy}/>
            <Route path="/Userdetails" exact component={Userdetails}/>
            <Route path="/Enterprisesub" exact component={Enterprisesub}/>
            <Route path="/enterprise-price" exact component={enterprisePrice}/>
            <Route path="/talentCompy" exact component={talentCompy} />
            <Route path="/Coaches" exact component={Coaches}/>
            <Route path="/youareacompany" exact component={YouareaCompany} />
            <Route path="/youareaconsultant" exact component={YouareaConsultant} />
            <Route path="/recruitmentsource" exact component={recruitmentsource} />
            <Route path="/enterprise" exact component={Enterprise} />
            <Route path="/full-skills-assessment" exact component={FullSkillAssessment} />            
            <Route path="/supportresources" exact component={SupportResources} />            
            <Route path="/securerecruitments" exact component={SecureRecruitments} />            
            <Route path="/sourceresources" exact component={SourceResources} />            
            <Route path="/entrepreneur-skills-assessment" exact component={EntrepreneurSkillAssessment} />            
            <Route
              path="/franchise/:hr?"
              exact
              component={AuthGuard(Franchise)}
            />
            <Route
              path="/business-creation"
              exact
              component={AuthGuard(BussinessCreation)}
            />
            <Route path="/sale/:hr?" exact component={AuthGuard(Sale)} />
            {/* <Route
              path="/creation/:hr?"
              exact
              component={AuthGuard(Entrepreneur)}
            /> */}
            <Route path="/sale-force/:testId/:op?" exact component={AuthGuard(Sale)} />            
            
            <Route
              path="/creation-link/:testId/:op?"
              exact
              component={AuthGuard(BussinessCreation)}
            />    
            
            <Route
              path="/creation/:testId?"
              exact
              component={AuthGuard(BussinessCreation)}
            />            
            <Route path="/recruitment-link/:testId/:op?" exact component={AuthGuard(Recruitment)}/>
            <Route path="/recruitment/:testId?" exact component={AuthGuard(Recruitment)}/>
            <Route path="/coaching-tool/:testId/:op?" exact component={AuthGuard(CoachingCareer)}/>
            {/* <Route path="/recruitment/:testId" exact component={AuthGuard(Recruitment)}/> */}
            <Route path="/buy-test" exact component={AuthGuard(Payment)} />
            <Route path="/buy-subscription" exact component={Buysubscription} />
            <Route path="/coaching-career/:hr?" exact component={AuthGuard(CoachingCareer)}/>
            <Route
              path="/create-link"
              exact
              component={AuthGuard(CreateLink)}
            />
            <Route path="/profile" exact component={AuthGuard(CreateLink)} />
            <Route path="/about-us" exact component={Aboutus} />
            <Route path="/ourClient" exact component={ourClient} />
            <Route path="/partner" exact component={Partner} />
            {/* <Route path="/story" exact component={Story} />
            <Route path="/about-value" exact component={AboutValue} />
            <Route path="/our-benefits" exact component={OurBenefits} /> */}
            <Route path="/about-section/:id?" exact component={AboutSection} />
            {/* <Route path="/good-reasons" exact component={GoodReasons} /> */}
            {/* <Route path="/aboutus-testimonials" exact component={AboutUsTestimonials} /> */}
            {/* <Route path="/aboutus-faq" exact component={AboutUsFAQ} /> */}
            <Route path="/Subscribe" exact component={Subscribe} />
            <Route path="/Result/:id?" exact component={Result} />
            <Route path="/Resultsale/:id?" exact component={Resultsale} />
            <Route path="/RecruitmentResult/:id?" exact component={RecruitmentResult} />
            <Route path="/CreationResult/:id?" exact component={CreationResult} />
            <Route path="/CoachingCareerResult/:id?" exact component={CoachingCareerResult} />
            <Route path="/FAQ" exact component={FAQ} />
            <Route path="/contact-us" exact component={Contactus} />
            <Route path="/pre-registration" exact component={PreRegistration}/>
            <Route path="/pre-selection" exact component={PreSelection}/>
            <Route path="/pre-services" exact component={PreServices}/>
            <Route path="/contact-to-coach" exact component={AuthGuard(ContactToCoach)} />
            <Route path="/contact-professional" exact component={ContactToHr} />
            <Route path="/contact-enterprise" exact component={ContactToEnterprise} />
            <Route path="/mentions-legales" exact component={Legals} />
            <Route path="/conditions-generales" exact component={TermsConditions} />
            <Route path="/donnees-personnelles" exact component={PersonalData} />
            <Route path="/correlation" exact component={AuthGuard(Correlation)}/>            
            <Route path="/hr-candidates" exact component={AuthGuard(DisplayCandidates)}/>
            <Route path="/hr-jobprofile" exact component={AuthGuard(JobProfile)}/>
          </React.Fragment>
        </Router>
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => {
  return {
    lang: state.locale.lang
  };
};

export default connect(
  mapStateToProps,
  {}
)(App);
