import React, { Component } from "react";
import { Link } from "react-router-dom";
//import $ from "jquery";
import {
  FormattedMessage,
  FormattedHTMLMessage,
  IntlProvider,
} from "react-intl";
import Carousel from "react-bootstrap/Carousel";
import Footer from "./includes/Footer";
import Register from "./includes/Register";
import { history } from "../helper/History";
import $ from "jquery";
//import { FormattedHTMLMessage } from "react-intl";

class Youconsultant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dis: "",
    };
    window.scrollTo(0, 0); // scroll page to top
  }
  // componentDidMount(){
  //   if (this.props.isSignedIn) {

  //     this.setState({dis:"none"});
  //   }
  //   console.log(this.state.dis);
  // }

  showRegister(e) {
    var userType = localStorage.getItem("role");
    if (!userType) {
      history.push("/register");
      // console.log('in '+e);
      // $("#loginModal").modal("show");
      // $("#register-form").delay(100).fadeIn(100);
      // $("#login-form").fadeOut(100);
      // $("#login-form-link").removeClass("active");
      // console.log('Inside Header '+e);
      // if(e === '#register-form-link'){
      //   $('.modal-title').text('SIGNUP');
      //   $(e).addClass("active");
      // }else{
      //   $(e.target).addClass("active");
      // }
    }
  }

  sendparam() {
    localStorage.setItem("classic", "classic");
  }
  showRegister2(e) {
    console.log("in " + e);
    $("#loginModal").modal("show");
    $("#register-form").delay(100).fadeIn(100);
    $("#login-form").fadeOut(100);
    $("#login-form-link").removeClass("active");
    console.log("Inside Header " + e);
    if (e === "#register-form-link") {
      $(".modal-title").text("SIGNUP");
      $(e).addClass("active");
    } else {
      $(e.target).addClass("active");
    }
  }

  render() {
    return (
      <React.Fragment>
        <section id="wrapper" className="icon-boxed1 teaser-box ">
          <div className="demo-btn-block mt-5 mb-3" style={{ display: "flex" }}>
            {/* <Link className="btn btn-primary p-link" to="/contact-professional" style={{background:'#28326a',marginBottom:'3%', width:'270px', marginLeft:'-2px', marginTop:'2%', height:'55px',border:"none",textTransform:'none'}}>
         <p className="demo-btn" > <FormattedHTMLMessage id="Nos.solutions.pour.les.Consultant.es" defaultMessage="FAQ"/></p>
       </Link> */}

            <Link
              className="btn btn-primary p-link consultant-top-button"
              
              style={{
                background: "rgb(40, 50, 106)",
              }}
            >
              <p className="demo-btn">
                {" "}
                <FormattedHTMLMessage
                  id="consultant.title_button"
                  defaultMessage="FAQ"
                />
              </p>
            </Link>
          </div>
          <div className="">
            <div className="">
              <div className="container">
                <div className="row">
                 

                  <div className="col-lg-4 col-md-4 responsive-mb consultant-container">
                    <Link to="/hr" style={{ textDecoration: "none" }}>
                      <div className="icon-box text-left hero-bg box-shadow  boxHeight" style={{ height:"88%" }}>
                        {/* <div className="teaser-icon icon-styled bg-maincolor3 m-auto d-flex align-items-center justify-content-center">
                    <img src="assets/images/scientific.png" alt="Scientific" />
                  </div> */}
                        <div>
                          <p className=" text-center ">
                            {/* <Link to="/" className=""> */}
                            <b>
                              <FormattedHTMLMessage
                                id="you.company.title3"
                                defaultMessage="starter"
                              />
                              {/* </Link> */}
                            </b>
                          </p>

                          <p style={{ color: "white" }}>
                            <FormattedHTMLMessage
                              id="you.company.card3"
                              defaultMessage="12 months"
                            />
                          </p>

            
                        </div>
                        <div class="BtnBotm">
                          {" "}
                          <Link
                            to="/hr"
                            className="nav-link text-center"
                            data-target=".navbar-collapse.show"
                          >
                            <button class="btn btn-primary btnDiscover">
                              <FormattedHTMLMessage
                                id="Discover"
                                defaultMessage="Subscribe"
                              />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-lg-4 col-md-4 responsive-mb consultant-container">
                    <Link to="/partner" style={{ textDecoration: "none" }}>
                      <div className="icon-box text-left hero-bg box-shadow boxHeight" style={{ height:"88%" }}>
                        <div style={{ height: "338px" }}>
                          <p className=" text-center ">
                            <b>
                              <FormattedHTMLMessage
                                id="you.consultant.title2"
                                defaultMessage="Classic"
                              />
                            </b>
                          </p>
                          {/* <p>
                    <FormattedHTMLMessage
                      id="Starter.subtitle.2"
                      defaultMessage="12 months"
                    />
                  </p> */}

                          <p style={{ color: "white" }}>
                            <FormattedHTMLMessage
                              id="you.consultant.card2"
                              defaultMessage="€49.90"
                            />
                          </p>
                          {/* <p>
                     <FormattedHTMLMessage
                      id="Starter.subpricetitle1"
                      defaultMessage="per month - annual payment"
                    />
                    </p> */}

                          {/* <p><FormattedHTMLMessage id="Starter.user" defaultMessage="1 user" /></p>    */}
                        </div>
                        <div class="BtnBotm">
                          <Link
                            to="/partner"
                            className="nav-link  text-center "
                            data-target=".navbar-collapse.show"
                          >
                            <button
                              class="btn btn-primary btnDiscover "
                              onClick={this.sendparam()}
                            >
                              <FormattedHTMLMessage
                                id="you.consult.card3.btn"
                                defaultMessage="Subscribe"
                              />
                            </button>
                          </Link>
                        </div>
                      </div>
                      {/* <h6><FormattedHTMLMessage id="Description" defaultMessage="Description"/></h6>  
                <p><FormattedHTMLMessage id="Description.paragraph2" defaultMessage="Description"/></p>   */}
                    </Link>
                  </div>

                  <div className="col-lg-3 col-md-3 responsive-mb consultant-container">
                    <a href="/assets/pdf/PILOTRH_TPE_PME_CABINET.pdf" style={{ textDecoration: "none" }} target="_blank">
                      <div className="icon-box text-left hero-bg box-shadow" style={{ width:"90%", height:"40%",marginTop:"10%", background:"#fff" }}>
                        <div >
                          <p className=" text-center " >
                            <b style={{ color:"#000" }}>
                          <FormattedHTMLMessage
                            id="coaching.pdf1"
                            defaultMessage="pdf"
                          /></b><br/>
                          <img
                              className="" style={{width:"80%"}}
                              src="assets/images/pdf.jpg"
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                      </a>
                      <a href="/assets/pdf/PILOTRH_CONSULTANT.pdf" style={{ textDecoration: "none" }} target="_blank">
                      <div className="icon-box text-left hero-bg box-shadow" style={{ width:"90%", height:"40%",marginTop:"7%", background:"#fff" }}>
                        <div >
                        <p className=" text-center " >
                        <b style={{ color:"#000" }}>
                          <FormattedHTMLMessage
                            id="coaching.pdf2"
                            defaultMessage="pdf"
                          /></b><br/>
                          <img
                              className="" style={{width:"80%"}}
                              src="assets/images/pdf.jpg"
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Youconsultant;
