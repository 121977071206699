import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import Carousel from "react-bootstrap/Carousel";
import Footer from "./includes/Footer";

class ourClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
    };
  }
  componentDidMount() {
    const element = document.getElementById(this.props.match.params.id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section

      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <section id="wrappers">
          <div
            className="container about pages text-justify"
            style={{ maxWidth: "892px" }}
          >
            <div className="row" id="story">
              <div
                className="col-md-12 img-content"
                style={{
                  background: "rgb(255 255 255)",
                  color: "#000",
                  marginBottom: "1%",
                  padding: "40px",
                  marginTop:"4%",
                }}
              >
                <div className="Brands_wrapper">
                  <div class="brand_heading">
                    <h5>AERONAUTIQUE</h5>
                  </div>
                  <div className="brand-logos">
                    <img
                      className="logo-space air"
                      src="assets/images/LOGO AIR FRANCE.png"
                      alt="hello"
                    />
                    <img
                      className="logo-space safran"
                      src="assets/images/LOGO SAFRAN.png"
                      alt="hello"
                    />
                  </div>
                </div>
                <div className="Brands_wrapper">
                  <div class="brand_heading">
                    <h5>DISTRIBUTION</h5>
                  </div>
                  <div className="brand-logos">
                    <img
                      className="logo-space"
                      src="assets/images/LOGO MOUSQUETAIRES.png"
                      alt="hello"
                    />
                    <img
                      className="logo-space"
                      src="assets/images/LOGO INTERMARCHE.png"
                      alt="hello"
                    />
                    <img
                      className="logo-space"
                      src="assets/images/LOGO BRICO.png"
                      alt="hello"
                    />
                    <img
                      className="logo-space"
                      src="assets/images/LOGO ROADY.png"
                      alt="hello"
                    />
                    <img
                      className="logo-space"
                      src="assets/images/LOGOPOIVREROE.png"
                      alt="hello"
                    />
                  </div>
                </div>
                <div className="Brands_wrapper">
                  <div class="brand_heading">
                    <h5>AUDIOVISUEL</h5>
                  </div>
                  <div className="brand-logos">
                    <img
                      className="logo-space wb"
                      src="assets/images/LOGO WARNER BROSS.png"
                      alt="hello"
                    />
                  </div>
                </div>
                <div className="Brands_wrapper">
                  <div class="brand_heading">
                    <h5>SERVICES</h5>
                  </div>
                  <div className="brand-logos">
                    <img
                      className="logo-space"
                      src="assets/images/LOGO ADHAP.png"
                      alt="hello"
                    />
                    <img
                      className="logo-space"
                      src="assets/images/LOGO DOMIDOM.png"
                      alt="hello"
                    />
                    <img
                      className="logo-space"
                      src="assets/images/LOGO LMDT.png"
                      alt="hello"
                    />
                    <img
                      className="logo-space"
                      src="assets/images/LOGO FAMILYSPHERE.png"
                      alt="hello"
                    />
                  </div>
                </div>
                <div className="Brands_wrapper">
                  <div class="brand_heading">
                    <h5>PUBLIC</h5>
                  </div>
                  <div className="brand-logos">
                    <img
                      className="logo-space"
                      src="assets/images/LOGO DEFENSE MOBILITE.png"
                      alt="hello"
                    />
                  </div>
                </div>

                <div className="Brands_wrapper">
                  <div class="brand_heading">
                    <h5>GRANDES ECOLES</h5>
                  </div>
                  <div className="brand-logos">
                    <img
                      className="logo-space science-po"
                      src="assets/images/LOGO SCIENCE PO.png"
                      alt="hello"
                    />
                    <img
                      className="logo-space celsa-height"
                      src="assets/images/LOGO SORBONE CELSA.png"
                      alt="hello"
                    />
                     <img
                      className="logo-space celsa-height"
                      src="assets/images/LOGO BANNIERE IAE.jpg"
                      alt="hello"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row " id="testi">
              <div
                className="col-md-12 img-content"
                style={{
                  background: "rgb(21 149 165)",
                  color: "#fff",
                  marginBottom: "1%",
                  padding: "40px",
                  marginTop:"6%",
                }}
              >
                <p>
                  <b style={{ color: "#fff", fontSize: "20px" }}>
                    <FormattedHTMLMessage id="aboutus.menutitle5" />
                  </b>
                </p>
                <p>
                  <b style={{ color: "#fff", fontSize: "15px" }}>
                    <FormattedHTMLMessage id="testimonial.title" />
                  </b>
                </p>
                <p class="testimonial-review">
                  <Carousel>
                    <Carousel.Item>
                      <div>
                        <FormattedHTMLMessage id="testimonial.desc1" />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div>
                        <FormattedHTMLMessage id="testimonial.desc2" />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div>
                        <FormattedHTMLMessage id="testimonial.desc3" />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div>
                        <FormattedHTMLMessage id="testimonial.desc4" />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div>
                        <FormattedHTMLMessage id="testimonial.desc5" />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div>
                        <FormattedHTMLMessage id="testimonial.desc6" />
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    lang: state.locale.lang,
  };
};

ourClient = connect(mapStateToProps, {})(ourClient);

export default ourClient;
