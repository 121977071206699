import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import $ from "jquery";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import ReactCountryFlag from "react-country-flag";

import { ROOT_URL, APP_URL } from "../../helper/Config";
//import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { login, logout, clearMessage, setLocale } from "../../actions";
import Register from "./Register";
import Cookies from 'js-cookie';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      remainingTest: 0,
      language: null,
      forgetemail: null,
      cookieAccepted: false,
      cookieRefused: false,
      error: '',
    };
    this.loginUser = this.loginUser.bind(this);
    this.forgetUser = this.forgetUser.bind(this);
    this.handleforgetemailChange = this.handleforgetemailChange.bind(this);
    axios
      .get(`${ROOT_URL}/getRoles`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        //console.log(response);
        this.setState({
          roles: response.data.roles,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
 
  handleCookieModal = () => {
    localStorage.setItem('cookieConsent', 'setup');
    $("#cookiesSetupSignal").modal("show");
    
  }
  acceptCookies = () => {
    // Set cookies and update state
    document.cookie = "userCookie=accepted; max-age=31536000; path=/"; // 1 year cookie
    localStorage.setItem('cookieConsent', 'accepted');
    this.setState({ cookieAccepted: true });
    $("#cookiesModal").modal("hide");
    $("#CookieConsentConfigure").hide();
  }

  refuseCookies = () => {
    // Remove cookies and update state
    document.cookie = "userCookie=; max-age=0; path=/"; // Removing cookie
    localStorage.setItem('cookieConsent', 'refused');
    this.setState({ cookieRefused: true });
    $("#cookiesModal").modal("hide");
    $("#CookieConsentConfigure").hide();
    $("#cookiesRefuseSignal").modal("show");
   
  }

 

  setupCookies = () => {
    // Remove cookies and update state
    document.cookie = "userCookie=; max-age=0; path=/"; // Removing cookie
    localStorage.setItem('cookieConsent', 'setup');
    this.setState({ cookieRefused: true });
    $("#cookiesModal").modal("hide");
    $("#cookiesSetupSignal").modal("show");
  }

  cookiesF = () => {
    localStorage.setItem('cookieSetupF', 'cs-f');
    this.setState({ error: '' });
  }
  cookiesS = () => {
    localStorage.setItem('cookieSetupS', 'cs-s');
    this.setState({ error: '' });
  }
  cookiesM = () => {
    localStorage.setItem('cookieSetupM', 'cs-m');
    this.setState({ error: '' });
  }

  cookiesValid = () => {
    const setupF = localStorage.getItem('cookieSetupF');
    const setupS = localStorage.getItem('cookieSetupS');
    const setupM = localStorage.getItem('cookieSetupM');
    $("#CookieConsentConfigure").show();
    if(setupF === 'cs-f' || setupS === 'cs-s' || setupM === 'cs-m') {
      
      $("#cookiesSetupSignal").show();
    } else {
      this.setState({ error: 'Select any option.' });
    }
    //cookiesSetupSignal
  }

  cookiesReturn = () => {
    // Cookies.remove('cookieSetupM');
    // Cookies.remove('cookieSetupS');
    // Cookies.remove('cookieSetupF');
    $("#cookiesModal").modal("show");
    $("#cookiesModal").modal({backdrop: true});
  }

 

  handleRefuseConsent = () => {
    const setupF = localStorage.getItem('cookieSetupF');
    const setupS = localStorage.getItem('cookieSetupS');
    const setupM = localStorage.getItem('cookieSetupM');
    $("#cookiesRefuseSignal").modal("hide");
    if(setupF === 'cs-f' || setupS === 'cs-s' || setupM === 'cs-m') {
      $("#CookieConsentConfigure").hide();
    } else {
      $("#CookieConsentConfigure").show();
    }
  }

  refuseReturn = () => {
    $("#cookiesModal").modal("show");
    $("#cookiesModal").modal({backdrop: true});
  }

  


  handleforgetemailChange(e) {
    this.setState({ forgetemail: e.target.value });
  }
  updateContent(language) {
    //console.log(' -- '+language);
    const popupContent =
      language === "en"
        ? "<ul className='list-group'><li className='list-group-item'>Choose your language and click <b>LOGIN</b> at the top right of the screen. An identification window opens.</li><li className='list-group-item'>Click <b>SIGN UP</b> at the bottom right of the window.</li><li className='list-group-item'>Complete the registration form by choosing the 'Individual' or ‘Professional’ section and then click <b>SIGN UP</b>.</li></ul>"
        : "<ul className='list-group'><li className='list-group-item'>Choisir votre langue et cliquez sur <b>LOGIN</b> en haut à droite de l’écran. Une fenêtre d’identification s’ouvre.</li><li className='list-group-item'>Cliquez sur <b>S’INSCRIRE</b> en bas à droite,de la fenêtre.</li><li className='list-group-item'>Complétez le formulaire d’inscription en choisissant la rubrique ‘Particulier’ ou ‘Professionnel’ suivant le cas et cliquez sur <b>S’INSCRIRE</b>.</li></ul>";
    //console.log('updateContent => '+popupContent+' lang '+language);
    $("button.dropdown-item").attr("data-content", popupContent);
  }

  logout(e) {
    e.preventDefault();
    this.setState({
      remainingTest: "0",
    });
    this.props.logout();
  }

  openLogin(e) {
    $("#loginModal").modal("show");
    // $("#register-form").delay(100).fadeIn(100);
    // $("#login-form").fadeOut(100);
    // $("#login-form-link").removeClass("active");
    // console.log('Inside Header '+e);
    // if(e === '#register-form-link'){
    //   $('.modal-title').text('SIGNUP');
    //   $(e).addClass("active");
    // }else{
    // //   $(e.target).addClass("active");
    // // }
  }

  openLogin2(e) {
    $("#loginModal").modal("show");
    $("#register-form").delay(100).fadeIn(100);
    $("#login-form").fadeOut(100);
    $("#login-form-link").removeClass("active");
    //console.log("Inside Header " + e);
    if (e === "#register-form-link") {
      $(".modal-title").text("SIGNUP");
      $(e).addClass("active");
    } else {
      //   $(e.target).addClass("active");
    }
  }
  openClickNav() {
    $(".navbar-collapse").collapse("hide");
  }

  openMenuDropdown() {
    if (this.props.isSignedIn) {
      var userid = localStorage.getItem("userId");
      //console.log("user loggedIN "+userid);
      var api_url = `${ROOT_URL}/getTests/${userid}`;
      // get no of users test
      axios
        .get(api_url, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          //console.log('Inside componentDidMount '+response.data.tests);
          this.setState({
            remainingTest: response.data.tests,
          });
        })
        .catch((error) => {
         // console.log(error);
        });
    }
  }

  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="popover"]').popover({
        placement: "left",
        trigger: "hover",
        html: true,
      });
    });
    if (this.props.isSignedIn) {
      var userid = localStorage.getItem("userId");
      //console.log("user loggedIN "+userid);
      var api_url = `${ROOT_URL}/getTests/${userid}`;
      // get no of users test
      axios
        .get(api_url, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          //console.log('Inside componentDidMount '+response.data.tests);
          this.setState({
            remainingTest: response.data.tests,
          });
        })
        .catch((error) => {
         // console.log(error);
        });
    }
    const setLang = localStorage.getItem('lang');
    if(!setLang) {
      localStorage.setItem("lang","fr");
    }
    
    const cookieConsent = localStorage.getItem('cookieConsent');
    const setupF = localStorage.getItem('cookieSetupF');
    const setupS = localStorage.getItem('cookieSetupS');
    const setupM = localStorage.getItem('cookieSetupM');
    if (cookieConsent === 'accepted') {
      this.setState({ cookieAccepted: true });
    } else if (cookieConsent === 'refused') {
      this.setState({ cookieRefused: true });
    }
    const getConsent = localStorage.getItem("cookieConsent");
    if(getConsent === 'accepted' || getConsent === 'setup' || getConsent === 'refused') {
      $("#cookiesModal").modal("hide");
    } else {
      $("#cookiesModal").modal("show");
      $("#cookiesModal").modal({backdrop: true});
    }
    // if(getConsent === 'refused') {
    //   $("#CookieConsentConfigure").show();
    // }
    if(getConsent === 'setup') {
      // console.log(setupF);
      if(setupF === 'cs-f' || setupS === 'cs-s' || setupM === 'cs-m') {
        // console.log(1);
        $("#CookieConsentConfigure").show();
      } else {
        $("#CookieConsentConfigure").show();
      }
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    // if (this.props.isSignedIn !== prevProps.isSignedIn) {
    //   //console.log("state is differ");
    // }
  }

  renderNewnav() {
    var userType = localStorage.getItem("role");
    const { cookieAccepted, cookieRefused } = this.state;
    const popupContent =
      this.props.lang === "en"
        ? "<ul className='list-group'><li className='list-group-item'>Choose your language and click <b>LOGIN</b> at the top right of the screen. An identification window opens.</li><li className='list-group-item'>Click <b>SIGN UP</b> at the bottom right of the window.</li><li className='list-group-item'>Complete the registration form by choosing the 'Individual' or ‘Professional’ section and then click <b>SIGN UP</b>.</li></ul>"
        : "<ul className='list-group'><li className='list-group-item'>Choisir votre langue et cliquez sur <b>LOGIN</b> en haut à droite de l’écran. Une fenêtre d’identification s’ouvre.</li><li className='list-group-item'>Cliquez sur <b>S’INSCRIRE</b> en bas à droite,de la fenêtre.</li><li className='list-group-item'>Complétez le formulaire d’inscription en choisissant la rubrique ‘Particulier’ ou ‘Professionnel’ suivant le cas et cliquez sur <b>S’INSCRIRE</b>.</li></ul>";
    return (
      <React.Fragment>
        <Link
          to="/"
          className="nav-link  dropdown-toggle"
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style={{ color: "#00008B" }}
          // onClick={e => this.openMenuDropdown()}
        >
          <FormattedMessage id="sub.menue0" defaultMessage="OUR STORY" />
        </Link>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          {/* <Link className="nav-link p-link" to="/youareacompany" > */}
          <a href="/youareaconsultant" className="nav-link p-link">
            {/* <button type="button" className="dropdown-item nav-link"  data-toggle="popover"> */}
            <p>
              <FormattedMessage
                id="sub.menue1"
                defaultMessage="You are a Company"
              />
            </p>
            {/* </button> */}
            {/* </Link> */}
          </a>
          {/* <Link className="nav-link p-link" to="/youareaconsultant" > */}
          <a href="/youareacompany" className="nav-link p-link">
            {/* <button type="button" className="dropdown-item nav-link"  data-toggle="popover"> */}
            <p>
              <FormattedMessage id="sub.menue2" defaultMessage="OUR STORY" />
            </p>
            {/* </button> */}
            {/* </Link> */}
          </a>
          {/* <Link className="nav-link p-link" to="/individual" > */}
          {/* <a href="/individual" className="nav-link p-link"> */}
            {/* <button type="button" className="dropdown-item nav-link"  data-toggle="popover"> */}
            {/* <p>
              <FormattedMessage id="sub.menue3" defaultMessage="OUR STORY" />
            </p> */}
            {/* </button> */}
            {/* </Link> */}
          {/* </a> */}
        </div>
      </React.Fragment>
    );
  }

  renderAboutnav() {
    var userType = localStorage.getItem("role");
    const popupContent =
      this.props.lang === "en"
        ? "<ul className='list-group'><li className='list-group-item'>Choose your language and click <b>LOGIN</b> at the top right of the screen. An identification window opens.</li><li className='list-group-item'>Click <b>SIGN UP</b> at the bottom right of the window.</li><li className='list-group-item'>Complete the registration form by choosing the 'Individual' or ‘Professional’ section and then click <b>SIGN UP</b>.</li></ul>"
        : "<ul className='list-group'><li className='list-group-item'>Choisir votre langue et cliquez sur <b>LOGIN</b> en haut à droite de l’écran. Une fenêtre d’identification s’ouvre.</li><li className='list-group-item'>Cliquez sur <b>S’INSCRIRE</b> en bas à droite,de la fenêtre.</li><li className='list-group-item'>Complétez le formulaire d’inscription en choisissant la rubrique ‘Particulier’ ou ‘Professionnel’ suivant le cas et cliquez sur <b>S’INSCRIRE</b>.</li></ul>";
    return (
      <React.Fragment>
        <Link
          to="/"
          className="nav-link  dropdown-toggle"
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style={{ color: "#00008B" }}
          // onClick={e => this.openMenuDropdown()}
        >
          <FormattedMessage id="head.aboutus" defaultMessage="ABOUT US" />
          {/* Your Solutions */}
        </Link>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a className="nav-link p-link" href="/about-section/story">
            <p>
              {" "}
              <FormattedMessage
                id="aboutus.menutitle1"
                defaultMessage="OUR STORY"
              />
            </p>
          </a>
          <a className="nav-link p-link" href="/about-section/values">
            <p>
              {" "}
              <FormattedMessage
                id="aboutus.menutitle2"
                defaultMessage="OUR VALUES"
              />
            </p>
          </a>
          <a className="nav-link p-link" href="/about-section/sect">
            <p>
              {" "}
              <FormattedMessage
                id="aboutus.menutitle3"
                defaultMessage="The benefits of our solutions"
              />
            </p>
          </a>
          <a className="nav-link p-link" href="/about-section/good">
            <p>
              {" "}
              <FormattedMessage
                id="aboutus.menutitle4"
                defaultMessage="8 good reasons to choose Pilot RH"
              />
            </p>
          </a>
          <a className="nav-link p-link" href="/about-section/testi">
            <p>
              {" "}
              <FormattedMessage
                id="aboutus.menutitle5"
                defaultMessage="Testimonials"
              />
            </p>
          </a>
          <a className="nav-link p-link" href="/about-section/faq">
            <p>
              {" "}
              <FormattedMessage id="FAQ.title" defaultMessage="FAQ" />
            </p>
          </a>
        </div>
      </React.Fragment>
    );
  }

  renderAuthButton2() {
    var userType = localStorage.getItem("userRole");
    if (userType !== "User") {
      var fileLink =
        this.props.lang === "en"
          ? "/assets/pdf/hr-pro-user-guide.pdf"
          : "/assets/pdf/guide-utilisateur-professionnel.pdf";
    } else {
      var fileLink =
        this.props.lang === "en"
          ? "/assets/pdf/individual-user-guide.pdf"
          : "/assets/pdf/guide-utilisateur-particulier.pdf";
    }
    //console.log('userType => '+userType+' lang '+this.props.lang+' fileLink '+fileLink);
    // convert first connection popup
    const popupContent =
      this.props.lang === "en"
        ? "<ul className='list-group'><li className='list-group-item'>Choose your language and click <b>LOGIN</b> at the top right of the screen. An identification window opens.</li><li className='list-group-item'>Click <b>SIGN UP</b> at the bottom right of the window.</li><li className='list-group-item'>Complete the registration form by choosing the 'Individual' or ‘Professional’ section and then click <b>SIGN UP</b>.</li></ul>"
        : "<ul className='list-group'><li className='list-group-item'>Choisir votre langue et cliquez sur <b>LOGIN</b> en haut à droite de l’écran. Une fenêtre d’identification s’ouvre.</li><li className='list-group-item'>Cliquez sur <b>S’INSCRIRE</b> en bas à droite,de la fenêtre.</li><li className='list-group-item'>Complétez le formulaire d’inscription en choisissant la rubrique ‘Particulier’ ou ‘Professionnel’ suivant le cas et cliquez sur <b>S’INSCRIRE</b>.</li></ul>";
      const ent =   this.props.lang === "en"
        ? "Enterprise"
        :"Entreprise";
        const indi=  this.props.lang === "en"
        ? "Individual "
        :"Particulier ";
       

        if (this.props.isSignedIn) {
      let { remainingTest } = this.state;
      return (
        <React.Fragment>
          <Link to="/Userdetails" className="nav-link ">
            {" "}
            <span>{localStorage.getItem("userName")}</span>
            {this.props.lang === "en"}
            <br></br><span>{localStorage.getItem("userRole")=="Hr"? <FormattedHTMLMessage
                            id="nav.consultant_title"
                            defaultMessage="For Consultant"
                          />
                          :localStorage.getItem("userRole")=='Enterprise'?
                          <FormattedHTMLMessage
                            id="nav.enterprise_title"
                            defaultMessage="For Enterprise"
                          />
                          :localStorage.getItem("userRole")=='User'?indi :" "}</span>
            
          </Link>
        </React.Fragment>
      );
    } 
  }
  renderAuthButton() {
    var userType = localStorage.getItem("userRole");
    if (userType == "Hr") {
      var fileLink =
        this.props.lang === "en"
        
          ? "/assets/pdf/hr-pro-user-guide.pdf"
          : "/assets/pdf/guide-utilisateur-professionnel.pdf";
    } 
    else if (userType == "Enterprise") {
      var fileLink =
        this.props.lang === "en"
        ? "/assets/pdf/company_user_guide.pdf"
        : "/assets/pdf/guide_utilisateur_enterprise.pdf";
    }    
    else {
      var fileLink =
        this.props.lang === "en"
          ? "/assets/pdf/individual-user-guide.pdf"
          : "/assets/pdf/guide-utilisateur-particulier.pdf";
    }
    //console.log('userType => '+userType+' lang '+this.props.lang+' fileLink '+fileLink);
    // convert first connection popup
    const popupContent =
      this.props.lang === "en"
        ? "<ul className='list-group'><li className='list-group-item'>Choose your language and click <b>LOGIN</b> at the top right of the screen. An identification window opens.</li><li className='list-group-item'>Click <b>SIGN UP</b> at the bottom right of the window.</li><li className='list-group-item'>Complete the registration form by choosing the 'Individual' or ‘Professional’ section and then click <b>SIGN UP</b>.</li></ul>"
        : "<ul className='list-group'><li className='list-group-item'>Choisir votre langue et cliquez sur <b>LOGIN</b> en haut à droite de l’écran. Une fenêtre d’identification s’ouvre.</li><li className='list-group-item'>Cliquez sur <b>S’INSCRIRE</b> en bas à droite,de la fenêtre.</li><li className='list-group-item'>Complétez le formulaire d’inscription en choisissant la rubrique ‘Particulier’ ou ‘Professionnel’ suivant le cas et cliquez sur <b>S’INSCRIRE</b>.</li></ul>";

    if (this.props.isSignedIn === null || !this.props.isSignedIn) {
      return (
        <React.Fragment>
          <Link
            to="/"
            className="nav-link dropdown-toggle login-btn"
            id="navbarDropdownMenuLink"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            // onClick={e => this.openMenuDropdown()}
          >
            Login
          </Link>
          <div
            className="dropdown-menu header-dropdown-menu"
            aria-labelledby="navbarDropdownMenuLink"
          >
            {this.props.lang === "en" ? (
              <Link className="nav-link p-link" to="/register">
                {/* <button type="button" className="dropdown-item nav-link"  data-toggle="popover"> */}
                <p>FIRST CONNECTION</p>
                {/* </button> */}
              </Link>
            ) : (
              //  onMouseOver={this.updateContent.bind(this.props.lang)} data-content={popupContent}
              // onClick={e => this.openLogin2()}
              <Link className="nav-link p-link" to="/register">
                {/* <button type="button" className="dropdown-item nav-link"  data-toggle="popover"> */}
                <p>PREMIERE CONNEXION</p>
                {/* </button> */}
              </Link>
            )}
            {/* onClick={e => this.openLogin()} */}
            <Link className="nav-link p-link" to="/login">
              <span className="nav-link p-link">Login</span>
            </Link>
          </div>
        </React.Fragment>
      );
    } 
    else if (this.props.isSignedIn) {
      let { remainingTest } = this.state;
      return (
        <React.Fragment>
          {/* <Link
            to="/Userdetails"
            className="nav-link "
        
          >  <span>{localStorage.getItem("userName")}</span>
          </Link> */}

          <Link
            to="/"
            className="nav-link"
            id="navbarDropdownMenuLink"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span
              onClick={(e) => this.openMenuDropdown()}
              onMouseEnter={() => this.openMenuDropdown()}
            >
              {" "}
              <img src="assets/images/threedots.png" alt="" />{" "}
            </span>
            {/*  */}
          </Link>

          <div
            className="dropdown-menu"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <Link className="dropdown-item" target="_blank" to={fileLink}>
              <FormattedMessage
                id="header.user.guide"
                defaultMessage="USER GUIDE"
              />
            </Link>
            {/* {localStorage.getItem("userRole") === "Hr" ||
            localStorage.getItem("userRole") === "Enterprise" ||
            localStorage.getItem("userRole") === "User" ? (
              <Link className="dropdown-item" to="/Userdetails">
                <FormattedMessage
                  id="header.my.profile"
                  defaultMessage="My profile"
                />
              </Link>
            ) : null} */}
            {localStorage.getItem("userRole") === "Hr" ? (
              <Link className="dropdown-item" to="/NewBuy">
                <FormattedMessage
                  id="header.buy.sub"
                  defaultMessage="BUY TEST"
                />
              </Link>
            ) : localStorage.getItem("userRole") === "Enterprise" ? (
              <Link className="dropdown-item" to="/Enterprisesub">
                <FormattedMessage
                  id="header.buy.sub"
                  defaultMessage="BUY TEST"
                />
              </Link>
            ) : (
              ""
            )}
            {localStorage.getItem("userRole") === "Hr" ? (
              <Link className="dropdown-item" to="/buy-test">
                <FormattedMessage
                  id="header.buy.testhr"
                  defaultMessage="BUY TEST"
                />
              </Link>
            ) : localStorage.getItem("userRole") === "User" ? (
              <Link className="dropdown-item" to="/buy-test">
                <FormattedMessage
                  id="header.buy.test"
                  defaultMessage="BUY TEST"
                />
              </Link>
            ) : (
              ""
            )}
            {localStorage.getItem("userRole") === "Hr" ||
            localStorage.getItem("userRole") === "Enterprise" ? (
              <Link className="dropdown-item" to="/create-link">
                <FormattedMessage
                  id="header.test.link"
                  defaultMessage="SHARE TEST"
                />
              </Link>
            ) : null}
            {localStorage.getItem("userRole") === "Hr" ||
            localStorage.getItem("userRole") === "Enterprise" ? (
              <Link className="dropdown-item" to="/correlation">
                <FormattedMessage
                  id="header.match.profile"
                  defaultMessage="MATCHING"
                />
              </Link>
            ) : null}

            {localStorage.getItem("userRole") === "Hr" ||
            localStorage.getItem("userRole") === "Enterprise" ? (
              <Link className="dropdown-item" to="/hr-candidates">
                <FormattedMessage
                  id="header.candidates"
                  defaultMessage="Candidates"
                />
              </Link>
            ) : null}
            {localStorage.getItem("userRole") === "Hr" ||
            localStorage.getItem("userRole") === "Enterprise" ? (
              <Link className="dropdown-item" to="/hr-jobprofile">
                <FormattedMessage
                  id="header.job.profile"
                  defaultMessage="Job Profiles"
                />
              </Link>
            ) : null}
            {localStorage.getItem("userRole") === "Hr" ||
            localStorage.getItem("userRole") === "Enterprise" ? (
              <Link to="/buy-test" className="dropdown-item">
                <FormattedMessage
                  id="header.nooftesthr"
                  defaultMessage="TOTAL NO OF TEST"
                />{" "}
                <span id="remainingTest">({this.state.remainingTest})</span>
              </Link>
            ) : (
              <Link to="/buy-test" className="dropdown-item">
                <FormattedMessage
                  id="header.nooftest"
                  defaultMessage="TOTAL NO OF TEST"
                />{" "}
                <span id="remainingTest">({this.state.remainingTest})</span>
              </Link>
            )}

            <Link
              to="/"
              className="dropdown-item"
              onClick={this.logout.bind(this)}
              style={{color:"#28326A"}}
            >
              <FormattedMessage id="header.logout" defaultMessage="LOGOUT" />
            </Link>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <span className="nav-link p-link" onClick={(e) => this.openLogin()}>
          Login
        </span>
      );
    }
  }

  errorMessage() {
    //console.log(this.props.signupError+' --  '+this.props.successMessage+' props '+JSON.stringify(this.props));
    // check for signup error message
    if (this.props.signupError !== null && this.props.signupError !== "") {
      return (
        <div className="alert alert-danger" role="alert">
          {this.props.signupError}
        </div>
      );
    }
    // check for loginn error message
    if (this.props.errorMessage !== null && this.props.errorMessage !== "") {
      return (
        <div className="alert alert-danger" role="alert">
          {this.props.errorMessage}
        </div>
      );
    }
    if (
      this.props.successMessage !== null &&
      this.props.successMessage !== ""
    ) {
      return (
        <div className="alert alert-success" role="alert">
          {this.props.successMessage}
        </div>
      );
    }

    setTimeout(() => {
      this.props.clearMessage();
    }, 8000);
  }

  loginUser(values) {
    /*var userData = {
      grant_type: "password",
      client_id: "2",
      client_secret: "KNHif0NpXU3tVu9pIuVsWCLDIjOpv1uS3fvHUzCg",
      username: values.email,
      password: values.password
    };
    */
    // pass the languages in values
    //var language = localStorage.getItem("lang");
    values.language = this.props.lang;
    $("#login-loader").show();
    this.refs.sbmt.setAttribute("disabled", "disabled");

    this.props.login(values, this.refs.sbmt);
  }
  forgetUser(event) {
    /* event.preventDefault();
    let values = {};
    values.language = this.props.lang;
    values.forgetemail = this.state.forgetemail;
    $("#forgetpasswordModel").show();
    //this.refs.sbmt.setAttribute("disabled", "disabled");
    Forgetpassword(values, this.refs.sbmt ,response => {
      //console.log(' errors++++ '+JSON.stringify(response));
      if (response === "success") {
        $("#forgetpasswordModel").fadeOut(100);
      }else{
        console.log(' errors*** '+response);
        $("#loginModal").scrollTop(0);
      }
   });*/
  }

  renderInput(field) {
    return (
      <div className="form-group">
        <label>{field.label}</label>
        <input
          type={field.type}
          className="form-control"
          placeholder=""
          autoComplete="off"
          {...field.input}
        />
        {field.meta.touched && field.meta.error && (
          <small className="text-danger">{field.meta.error}</small>
        )}
      </div>
    );
  }

  showLogin(e) {
    $("#login-form").delay(100).fadeIn(100);
    $("#register-form").fadeOut(100);
    $("#register-form-link").removeClass("active");
    if (e === "#login-form-link") {
      $(e).addClass("active");
    } else {
      $(e.target).addClass("active");
    }
  }

  showForget(e) {
    $("#loginModal").modal("hide");
    $("#forgetpasswordModel").modal("show");
  }

  showRegister(e) {
    // console.log('in '+e);
    $("#register-form").delay(100).fadeIn(100);
    $("#login-form").fadeOut(100);
    $("#login-form-link").removeClass("active");
    // console.log('Inside Header '+e);
    if (e === "#register-form-link") {
      $(".modal-title").text("SIGNUP");
      $(e).addClass("active");
    } else {
      $(e.target).addClass("active");
    }
  }

  render() {
    const emailaddress = (
      <FormattedMessage id="signup.email" defaultMessage="Email" />
    );
    const password = (
      <FormattedMessage id="signup.password" defaultMessage="Password" />
    );
    const logoUrl = APP_URL + "assets/images/LOGO3.jpg";
    return (
      <React.Fragment>
        <section id="header">
          <div className="col-xl-11 col-lg-11 col-md-12 m-auto p-0">
            <nav className="navbar navbar-expand-lg  navbar-light">
              <div className="header_logo_center">
                <Link to="/" className="navbar-brand">
                  <img
                    src={logoUrl}
                    style={{ maxHeight: "75px" }}
                    alt="PilorRh Solution"
                  />
                </Link>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#collapsibleNavbar"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="collapsibleNavbar">
                <ul className="navbar-nav ml-auto">
                  {/* <li className="nav-item" onClick={e => this.openClickNav()}>
                    <Link to="/" className="nav-link"  data-target=".navbar-collapse.show">
                      <FormattedMessage
                        id="head.homepage"
                        defaultMessage="Homepage"
                      />
                    </Link>
                  </li>
                 */}
                  {/* <li className="nav-item" onClick={e => this.openClickNav()} >
                    <Link to="/hr" className="nav-link"  data-target=".navbar-collapse.show">
                      <span style={{color:"#00008B"}}>
                      <FormattedMessage
                        id="head.professional"
                        defaultMessage="HR PROFESSIONAL TOOLS"
                      />
                      </span>
                    </Link>
                  </li> */}
                  {/* 
                  <li style={{ borderLeft: '2px solid #00008B',
            height: '15px',
           marginTop:'16px',
           marginLeft:'5px',
           marginRight:'5px'}}>


                  </li>
                  */}
                  {/* <li className="nav-item" onClick={e => this.openClickNav()}>
                    <Link to="/enterprise" className="nav-link"  data-target=".navbar-collapse.show">
                    <span style={{color:"#00008B"}}>
                      <FormattedMessage
                        id="head.enterprise"
                        defaultMessage="Enterprise Solutions"
                      />
                      </span>
                    </Link>
                  </li>
            */}
                  {/* <li style={{ borderLeft: '2px solid #00008B',
            height: '15px',
           marginTop:'16px',
           marginLeft:'5px',
           marginRight:'5px'}}> */}

                  {/* </li> */}
                  {/* <li className="nav-item" onClick={e => this.openClickNav()}>
                    <Link to="/individual" className="nav-link"  data-target=".navbar-collapse.show">
                    <span style={{color:"#00008B"}}>
                      <FormattedMessage
                        id="head.individual"
                        defaultMessage="Individual"
                      />
                      </span>
                    </Link>
                  </li>
            */}
                  {/* <li style={{ borderLeft: '2px solid #00008B',
            height: '15px',
           marginTop:'16px',
           marginLeft:'5px',
           marginRight:'5px'}}>

                  </li>
                 */}
                  {/* <li className="nav-item" onClick={e => this.openClickNav()}>
                    <Link to="/FAQ" className="nav-link"  data-target=".navbar-collapse.show">
                      <FormattedMessage
                        id="head.FAQ"
                        defaultMessage="FAQ"
                      />
                    </Link>
                  </li>
                  <li className="nav-item aboutus" onClick={e => this.openClickNav()}>
                    <Link to="/about-us" className="nav-link"  data-target=".navbar-collapse.show">
                      <FormattedMessage
                        id="head.aboutus"
                        defaultMessage="About Us"
                      />
                    </Link>
                  </li> */}

                  {/* <li className="nav-item" onClick={e => this.openClickNav()}>
                    <Link to="" className="nav-link"  data-target=".navbar-collapse.show">
                      <FormattedMessage
                        id="head.Blog"
                        defaultMessage="Blog"
                      />
                    </Link>
                  </li> */}
                  {/* <li className="nav-item aboutus" onClick={e => this.openClickNav()}>
                    <Link to="/Subscribe" className="nav-link"  data-target=".navbar-collapse.show">
                    <span style={{color:"#00008B"}}>
                      <FormattedHTMLMessage
                        id="head.Subscribe"
                        defaultMessage="Subscribe"
                      />
                      </span>
                    </Link>
                  </li> */}
                  <li className="nav-item dropdown">{this.renderNewnav()}</li>
                  <li className="nav-item dropdown">{this.renderAboutnav()}</li>

                  {/* <li style={{ borderLeft: '2px solid #00008B',
            height: '15px',
           marginTop:'16px',
           marginLeft:'5px',
           marginRight:'5px'}}>

                  </li> */}

                  {/* <li className="nav-item aboutus" onClick={e => this.openClickNav()}>
                    <Link to="/contact-professional" className="nav-link"  data-target=".navbar-collapse.show">
                    <span style={{color:"#00008B"}}>
                      <FormattedHTMLMessage
                        id="head.15days"
                        defaultMessage="Subscribe"
                      />
                      </span>
                    </Link>
                  </li> */}

                  {/* <li style={{ borderLeft: '2px solid #00008B',
                      height: '15px',
                      marginTop:'16px',
                      marginLeft:'5px',
                      marginRight:'5px'}}>
                  </li>
                  <li className="nav-item aboutus" onClick={e => this.openClickNav()}>
                    <Link to="/about-us" className="nav-link"  data-target=".navbar-collapse.show">
                      <span style={{color:"#00008B"}}>
                      <FormattedMessage
                        id="head.aboutus"
                        defaultMessage="About Us"
                      />
                      </span>
                    </Link>
                  </li> */}

                  <li className="nav-item" onClick={(e) => this.openClickNav()}>
                    <Link
                      to="/ourClient"
                      className="nav-link"
                      data-target=".navbar-collapse.show"
                    >
                      <span style={{ color: "#00008B" }}>
                        <FormattedMessage
                          id="head.ourclient"
                          defaultMessage="Contact"
                        />
                      </span>
                    </Link>
                  </li>

                  <li className="nav-item" onClick={(e) => this.openClickNav()}>
                    <Link
                      to="/contact-us"
                      className="nav-link"
                      data-target=".navbar-collapse.show"
                    >
                      <span style={{ color: "#00008B" }}>
                        <FormattedMessage
                          id="head.contactus"
                          defaultMessage="Contact"
                        />
                      </span>
                    </Link>
                  </li>

                  <li
                    className="nav-item dropdown"
                    onClick={(e) => this.openClickNav()}
                  >
                    <div
                      className="nav-link dropdown-toggle"
                      id="dropdown09"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <ReactCountryFlag
                        code={this.props.lang === "en" ? "gb" : "fr"}
                        svg
                      />
                    </div>
                    <div className="dropdown-menu" aria-labelledby="dropdown09">
                      <div className="dropdown-item">
                        <div onClick={() => this.props.setLocale("fr")}>
                          <ReactCountryFlag code="fr" svg />
                        </div>
                      </div>
                      <div className="dropdown-item">
                        <div onClick={() => this.props.setLocale("en")}>
                          <ReactCountryFlag code="gb" svg />
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    {this.renderAuthButton2()}
                  </li>
                  <li className="nav-item dropdown three-dot-menu">
                    {this.renderAuthButton()}
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div id="CookieConsentConfigure" style={{alignItems: "baseline", display:"none", color: "white",  flexWrap: "wrap", justifyContent: "space-between", right: "0px", position: "fixed",  zIndex: "999", bottom: "0px"}}>
          {/* <div className="" style={{flex: "1 0 300px", margin: "15px"}}>
            <span>Nous utilisons des cookies pour fournir, maintenir et améliorer nos services et à des fins de sécurité et vous apporter des services adaptés.</span>
          </div> */}
          <div className="">
            <button onClick={this.handleCookieModal} id="rcc-confirm-button" aria-label="Accept cookies" style={{background: "rgb(255, 193, 7)", border: "0px", borderRadius: "0px", boxShadow: "none", color: "rgb(0, 0, 0)", cursor: "pointer", flex: "0 0 auto", padding: "5px 10px", margin: "15px", fontSize: "13px"}}>
              <span>Paramètres des cookies</span>
            </button>
          </div>
        </div>
        </section>


        <div
          className="modal fade"
          id="loginModal"
          // data-backdrop="static"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header bg-secondary">
                {/* <h4 className="modal-title text-center text-uppercase w-100 medium text-white font-20">
                   <FormattedMessage
                      id="head.login"
                      defaultMessage="Login"
                    />
                </h4> */}
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
              </div>
              <div className="modal-body">
                {/* <div className="col-md-12 mt-5">
                  {this.errorMessage()}
                  <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Field
                      name="username"
                      type="text"
                      label="Email"
                      component={this.renderInput}
                    />
                    <Field
                      name="password"
                      type="password"
                      label="Password"
                      component={this.renderInput}
                    />

                    <button
                      type="submit"
                      className="btn btn-primary float-left"
                      ref="sbmt"
                    >
                      Submit
                    </button>
                    <div className="float-left">
                      <div
                        className="spinner-border text-primary ml-2"
                        role="status"
                        id="login-loader"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </form>
                </div> */}

                <div className="col-md-12">
                  <div className="panel panel-login">
                    <div className="panel-heading">
                      <div className="row mb-3 border-bottom">
                        {/* <div className="col-md-6">
                          <p
                            className="active"
                            id="login-form-link"
                            onClick={e => this.showLogin(e)}
                          >
                          <FormattedMessage
                            id="head.login"
                            defaultMessage="Login"
                          />
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p
                            to="/"
                            id="register-form-link"
                            onClick={e => this.showRegister(e)}
                          >
                          <FormattedMessage
                            id="head.register"
                            defaultMessage="Register"
                          />
                          </p>
                        </div> */}
                      </div>
                    </div>
                    <div className="panel-body">
                      <div className="row mb-3 border-bottom">
                        <div className="col-md-12">
                          <h4 className="modal-title text-center text-uppercase w-100 medium text-grey font-20">
                            <FormattedMessage id="head.login" />
                          </h4>
                          {this.errorMessage()}
                          <form
                            id="login-form"
                            onSubmit={this.props.handleSubmit(this.loginUser)}
                          >
                            <Field
                              name="email"
                              type="text"
                              label={emailaddress}
                              component={this.renderInput}
                            />
                            <Field
                              name="password"
                              type="password"
                              label={password}
                              component={this.renderInput}
                            />

                            <div className="form-group">
                              <div className="row">
                                <div className="m-auto d-flex">
                                  <button
                                    type="submit"
                                    name="login-submit"
                                    id="login-submit"
                                    tabIndex="4"
                                    className="form-control btn btn-primary mr-3 whitespacenowrap"
                                    ref="sbmt"
                                  >
                                    <FormattedMessage
                                      id="signup.login"
                                      defaultMessage="lOG IN"
                                    />
                                  </button>
                                </div>
                              </div>
                              <p className="text-center">
                                <a href="https://www.pilotrhsolutions.com/pilotRH/password/reset">
                                  <FormattedMessage
                                    id="signup.forget"
                                    defaultMessage="Forgot password ?"
                                  />
                                </a>
                              </p>
                              <hr />
                              <div className="row">
                                <div className="m-auto d-flex signuppage">
                                  <FormattedMessage
                                    id="login.click.desc"
                                    defaultMessage="Do not have account yet Click to?"
                                  />
                                  &nbsp; &nbsp;
                                  <Link
                                    className=""
                                    to="#"
                                    onClick={(e) =>
                                      this.showRegister("#register-form-link")
                                    }
                                  >
                                    <FormattedMessage
                                      id="signup.signup"
                                      defaultMessage=" SIGN UP"
                                    />
                                  </Link>
                                  {/* <button type="button" name="register-submit" id="register-submit" tabIndex="4"  className="" ref="sbmt" onClick={e => this.showRegister('#register-form-link')}>
                                  <FormattedMessage id="signup.signup" defaultMessage="SIGN UP"/>
                                </button> */}
                                </div>
                              </div>
                            </div>
                            <div className="float-left">
                              <div
                                className="spinner-border text-primary ml-2"
                                role="status"
                                id="login-loader"
                              >
                                <span className="sr-only">
                                  <FormattedMessage
                                    id="loading"
                                    defaultMessage="Loading..."
                                  />
                                </span>
                              </div>
                            </div>
                          </form>
                          <Register
                            Roles={this.state.roles}
                            lang={this.props.lang}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      
        <div
          className="modal fade"
          id="forgetpasswordModel"
          data-backdrop="static"
        >
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header bg-secondary">
                <h4 className="modal-title text-center text-uppercase w-100 medium text-white font-20">
                  <FormattedMessage
                    id="head.forgot"
                    defaultMessage="Forgot Password"
                  />
                </h4>
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
              </div>
              <div className="modal-body">
                <div className="col-md-12">
                  <div className="panel panel-login">
                    <div className="panel-body">
                      <div className="row mb-3 border-bottom">
                        <div className="col-md-12">
                          {this.errorMessage()}
                          <form id="forget-form" onSubmit={this.forgetUser}>
                            <div className="form-group">
                              <label>{emailaddress}</label>
                              <input
                                type="email"
                                name="forgetemail"
                                id="forgetemail"
                                className="form-control"
                                required
                                value={this.state.forgetemail}
                                onChange={this.handleforgetemailChange}
                              />
                            </div>
                            <div className="form-group">
                              <div className="row">
                                <div className="m-auto d-flex">
                                  <button
                                    type="submit"
                                    name="forget-submit"
                                    id="forget-submit"
                                    tabIndex="4"
                                    className="form-control btn btn-primary mr-3 whitespacenowrap"
                                    ref="sbmt"
                                  >
                                    <FormattedMessage
                                      id="signup.forget"
                                      defaultMessage="Apply"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                          <Register
                            Roles={this.state.roles}
                            lang={this.props.lang}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="errorModal"
          className="modal hide fade"
          tabIndex="-1"
          data-focus-on="input:first"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header bg-secondary">
                <h4 className="modal-title text-center text-uppercase w-100 medium text-white font-20">
                  Error !
                </h4>
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
              </div>
              <div className="modal-body">
                <div className="col-md-12" id="errorTxt"></div>
              </div>
            </div>
          </div>
        </div>
     
        <div
          className="modal fade"
          id="cookiesModal"
          data-backdrop="static"
          style={{ fontSize:"12px", textAlign:"justify" }}
        >
          <div className="overlay" >
          <div className="modal-dialog" style={{ maxWidth:"700px" }}>
            <div className="modal-content" style={{background:"#f8f9fa",marginTop:"15%"}}>
             
              <div className="modal-body" >
                <div className="col-md-12">
                  <div className="panel panel-login">
                    {/* <div className="panel-heading">
                      <div className="row mb-3 border-bottom">
                      </div>
                    </div> */}
                    <div className="panel-body" >
                      <div className="row mt-2 mb-2 border-bottom">
                        <div className="col-md-12">
                      <span>  <a class="navbar-brand" > <img
                          src={logoUrl}
                          style={{ maxHeight: "30px" }}
                          alt="PilorRh Solution"
                        /></a>  </span>
                        <FormattedHTMLMessage id="cookies-head"defaultMessage="MAKE A CHOICE FOR YOUR DATA" />
                        <p>
                        <FormattedHTMLMessage id="cookies-text1" defaultMessage="We use cookies or equivalent technology to store and/or access information on your device."/>
                        <FormattedHTMLMessage id="cookies-text2" defaultMessage="We use cookies or equivalent technology to store and/or access information on your device."/>
                        <FormattedHTMLMessage id="cookies-text3" defaultMessage="We use cookies or equivalent technology to store and/or access information on your device."/>

                        <FormattedHTMLMessage id="cookies-head2"defaultMessage="MAKE A CHOICE FOR YOUR DATA" />
                        <FormattedHTMLMessage id="cookies-text4" defaultMessage="We use cookies or equivalent technology to store and/or access information on your device."/>
                       
                        <FormattedHTMLMessage id="cookies-text5" defaultMessage="We use cookies or equivalent technology to store and/or access information on your device."/>
                       
                        <FormattedHTMLMessage id="cookies-text6" defaultMessage="We use cookies or equivalent technology to store and/or access information on your device."/>
                         </p>
                          <p style={{ textAlign:"center" }}>
                          <button onClick={this.acceptCookies}  class="cookies-btn" style={{background: "#fff",border: "1px solid red",color: "red",padding: "6px 20px"}}><FormattedHTMLMessage id="cookies-accept"defaultMessage="MAKE A CHOICE FOR YOUR DATA" /></button> <button onClick={this.refuseCookies} class="cookies-btn" style={{background: "#fff",border: "1px solid red",color: "red",padding: "6px 20px"}}><FormattedHTMLMessage id="cookies-refuse"defaultMessage="MAKE A CHOICE FOR YOUR DATA" /></button> 
                          <button onClick={this.setupCookies} class="cookies-btn" style={{background: "#fff",border: "1px solid red",color: "red",padding: "6px 20px"}}> 
                            <FormattedHTMLMessage id="cookies-setup"defaultMessage="MAKE A CHOICE FOR YOUR DATA" /></button>
                         </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>

        </div>

        <div
          className="modal fade"
          id="cookiesRefuseSignal"
          data-backdrop="static"
          style={{ fontSize:"12px" }}
        >
          <div className="modal-dialog" style={{ maxWidth:"700px" }}>
            <div className="modal-content" style={{background:"#fff",marginTop:"15%"}}>
              <div className="modal-body" >
                <div className="col-md-12">
                  <div className="panel panel-login">
                    {/* <div className="panel-heading">
                      <div className="row mb-3 border-bottom">
                      </div>
                    </div> */}
                    <div className="panel-body" >
                      <div className="row mt-2 mb-2 border-bottom">
                        <div className="col-md-12">
                        <span>  <a class="navbar-brand" > <img
                          src={logoUrl}
                          style={{ maxHeight: "30px" }}
                          alt="PilorRh Solution"
                        /></a>  </span>  
                        <p style={{ textAlign:"justify" }}>
                        <FormattedHTMLMessage id="cookies-refuse-signal" defaultMessage="cookies-refuse-signal" />
                        </p>
                        <p style={{ textAlign:"center" }}>
                        <button onClick={this.refuseReturn}  data-dismiss="modal" style={{background: "#fff",border: "1px solid red",color: "red",padding: "6px 20px"}}><FormattedHTMLMessage id="cookies-return" defaultMessage="Return" /></button> <button  data-dismiss="modal" style={{background: "#fff",border: "1px solid red",color: "red",padding: "6px 20px"}}><FormattedHTMLMessage id="cookies-valid" defaultMessage="" /></button> 
                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="cookiesSetupSignal"
          data-backdrop="static"
          style={{ fontSize:"15px" }}
        >
          <div className="modal-dialog" style={{ maxWidth:"700px" }}>
            <div className="modal-content" style={{background:"#fff",marginTop:"15%"}}>
              <div className="modal-body" >
                <div className="col-md-12">
                  <div className="panel panel-login">
                    {/* <div className="panel-heading">
                      <div className="row mb-3 border-bottom">
                      </div>
                    </div> */}
                    <div className="panel-body" >
                      <div className="row mt-2 mb-2 border-bottom">
                        <div className="col-md-12">
                        <span>  <a class="navbar-brand" > <img
                          src={logoUrl}
                          style={{ maxHeight: "30px" }}
                          alt="PilorRh Solution"
                        /></a>  </span>
                        
                        <p style={{ textAlign:"justify" }}>
                        <FormattedHTMLMessage id="cookies-setup-signal-head" defaultMessage="cookies-refuse-signal" />
                        </p>
                        <p>
                        {this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>}
                        <ul style={{listStyleType:'none',padding:'15px'}}>
                          <li style= {{marginBottom:'12px'}}>
                            <input type='checkbox' name='cs-f' onClick={this.cookiesF}/>  <FormattedHTMLMessage id="cookies-setup-signal1" defaultMessage="cookies-refuse-signal" />
                          </li>
                          <li style={{marginBottom:'12px'}}>
                            <input type='checkbox' name='cs-s' onClick={this.cookiesS}/>  <FormattedHTMLMessage id="cookies-setup-signal2" defaultMessage="cookies-refuse-signal" />
                          </li>
                          <li>
                            <input type='checkbox' name='cs-m' onClick={this.cookiesM}/>  <FormattedHTMLMessage id="cookies-setup-signal3" defaultMessage="cookies-refuse-signal" />
                          </li> 
                        </ul>
                        </p>
                        <p style={{ textAlign:"center" }}>
                          <button onClick={this.cookiesReturn} id="return_cookies" data-dismiss="modal" style={{background: "#fff",border: "1px solid red",color: "red",padding: "6px 20px"}}><FormattedHTMLMessage id="cookies-return" defaultMessage="Return" /></button> <button onClick={this.cookiesValid} data-dismiss="modal" style={{background: "#fff",border: "1px solid red",color: "red",padding: "6px 20px"}}><FormattedHTMLMessage id="cookies-valid" defaultMessage="" /></button> 
                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  
}

const validate = (values) => {
  const errors = {};

  if (!values.username) {
    errors.username = "Email is required";
  }
  if (
    values.username &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)
  ) {
    errors.username = "Invalid email";
  }
  if (!values.password) {
    errors.password = "Password is required";
  }
  return errors;
};
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    errorMessage: state.auth.loginError,
    signupError: state.auth.signupError,
    successMessage: state.auth.successMessage,
    lang: state.locale.lang,
  };
};

Header = connect(mapStateToProps, { login, logout, clearMessage, setLocale })(
  Header
);

export default reduxForm({
  form: "loginForm",
  validate,
})(Header);

/*export default connect(
  mapStateToProps,
  { login, logout }
)(Header);
*/
