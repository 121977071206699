import React,{ Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage,FormattedHTMLMessage } from "react-intl";
import { Field, reduxForm } from "redux-form";
import axios from "axios";
import { ROOT_URL, PAYPAL_KEY } from "../../helper/Config";
import { Link } from "react-router-dom";
import $ from "jquery";
import { history } from "../../helper/History";

import { PayPalButton } from "react-paypal-button-v2";
import { updaetPaymentData } from "../../actions/Payment";

//import AddressSection from "./AddressSection";
//import CardSection from "./CardSection";

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      error: false,
      errorMsg: "",
      loading: false,
      tests: 0,
      testname: '',
      amount: 0,
      currency: 'EUR',
      condition:'false',
      recuring:'false',
      promo:"",
    };
    //this.submit = this.submit.bind(this);
 
  }

  componentDidMount() {

    localStorage.removeItem("promos")
   

    if (this.props.isSignedIn) {
      if(localStorage.getItem("userRole")=='User'){
        history.push("/buy-test");
      }
      var userid = localStorage.getItem("userId");
      //console.log("user loggedIN "+userid);
      var api_url = `${ROOT_URL}/getTests/${userid}`;
      // get no of users test
      axios.get(api_url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }).then(response => {
		console.log('Inside  '+response.data.tests);
        this.setState({
          remainingTest: response.data.tests
        });
      }).catch(error => {
        console.log(error);
      });
    }

    
      else{
        
        history.push("/register");
      }
  }

  // handleSubmit = ev => {
  //   ev.preventDefault();
  //   // User clicked submit
  //   this.setState({ error: false });
  //   let { tests, amount } = this.state;
  //   if (tests && tests > 0 && amount > 0) {
  //     const token = localStorage.getItem("token");
  //     const userName = localStorage.getItem("userName");
  //     this.setState({ loading: true });
  //     var userType = localStorage.getItem("role");

  //     // this.props.stripe
  //     //   .createToken({
  //     //     name: userName
  //     //   })
  //     //   .then(
  //     //     resp => {
  //     //       //console.log(' resp+> '+JSON.stringify(resp));
  //     //       if (resp.token) {
  //     //         var submitData = {
  //     //           stripeToken: resp.token.id,
  //     //           amount: amount,
  //     //           tests: tests,
  //     //           userRole: userType
  //     //         };
  //     //         try {
  //     //           this.props.makeCharge(token, submitData, response => {
  //     //             //console.log(' errors===> '+JSON.stringify(response));
  //     //             //console.log(' errorsCode '+JSON.stringify(response.response.status));
  //     //             if(response.response.status === 200){
  //     //               if (response.status === "succeeded") {
  //     //                 this.setState({ complete: true });
  //     //               } else {
  //     //                 this.setState({
  //     //                   error: true,
  //     //                   errorMsg: "Error occur during payment"
  //     //                 });
  //     //               }
  //     //               this.setState({ loading: false });
  //     //             }else{
  //     //               this.setState({
  //     //                 error: true,
  //     //                 errorMsg: response.response.status+" Error!"+response.response.data.message
  //     //               });
  //     //               this.setState({ loading: false });
  //     //             }
  //     //           });
  //     //         } catch (error) {
  //     //           //console.log(' errors++++>> '+JSON.stringify(error));
  //     //           this.setState({
  //     //             error: true,
  //     //             errorMsg: error.message
  //     //           });
  //     //           this.setState({ loading: false });
  //     //         }
  //     //       } else {
  //     //         var errorMessage = (resp.error.message !== '' ? resp.error.message :'Enter valid card details');
  //     //         this.setState({
  //     //           loading: false,
  //     //           error: true,
  //     //           errorMsg: errorMessage
  //     //         });
  //     //       }
  //     //     },
  //     //     err => {
  //     //       console.log(err);
  //     //     }
  //     //   );
  //   } else {
  //     this.setState({
  //       loading: false,
  //       error: true,
  //       errorMsg: "Select No. of tests"
  //     });
  //   }

  //   // const data = await {
  //   //   stripeToken: stripe_token.token.id,
  //   //   amount: 2
  //   // };
  //   // console.log(stripe_token);
  //   //await this.props.makeCharge(token, stripe_token);

  //   // let {stripe_token} = await this.props.stripe.createToken({
  //   //   name: "Manpreet"
  //   // });
  //   // let response = await fetch("/charge", {
  //   //   method: "POST",
  //   //   headers: { "Content-Type": "text/plain" },
  //   //   body: stripe_token.id
  //   // });

  //   // if (response.ok) this.setState({ complete: true });
  // };

  changeTestCount(event) {
    var data = event.target.value.split('~');
  //
  //   var datas=Number(data[1]);
  //
  // console.log((datas*0.2));
  // var vat=datas*0.2;
  // var addedvat=datas+vat
    console.log('Inside charge amount '+event.target.value+' 1st  '+data[0]+' 2nd  '+data[1]);
    this.setState({
      tests: data[0],
      amount: data[1],
      // vatammount:addedvat,
      testname: data[2]
    });
  }
  changeTestCount2(event) {
    var data = event.target.value.split('~');
  //
    var datas=Number(data[1]);
  //
  // console.log((datas*0.2));
  var vat=datas*0.2;
  // var vat=0;
  var addedvats=datas+vat;
  var addedvat=parseFloat(addedvats).toFixed(2);
    console.log('Inside charge amount '+event.target.value+' 1st  '+data[0]+' 2nd  '+addedvat);
    this.setState({
      tests: data[0],
      amount: addedvat,
      // vatammount:addedvat,
      testname: data[2]
    });
    // const confirmBox = window.confirm(  "Your total amount including 20% vat for "+data[0]+" tests = "+addedvat+" "+this.state.currency);
  }
  onChecked(e){
    var data=e.target.value;

    this.setState({condition:data});
    console.log(this.state.condition)
  }

  onChecked2(e){
    var data=e.target.value;

    this.setState({recuring:data});
    console.log(this.state.recuring)
  }
  updateCurrency(e) {
    //console.log('Currency val  '+e.currentTarget.value+' ID '+'Currency val  '+e.currentTarget.id );
    this.setState({
      currency: e.currentTarget.value
    });
  }


  renderLoading = () => {
    if (this.state.loading) {
      return (
        <div className="p-overlay">
          <div
            className="spinner-border m-5"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  renderError = () => {
    let { error, errorMsg } = this.state;
    if (error) return <h4 className="text-danger text-center">{errorMsg}</h4>;
    else return null;
  };

  renderSelectBox= (userType) => {
    var option1    =  (this.props.lang === "en") ? "CLASSIC monthly (€390 per month)  " : "CLASSIC paiement mensuel (390 €/mois)   ";
    var option2    =  (this.props.lang === "en") ? "CLASSIC annual (€4200 per year) " : "CLASSIC paiement annuel (4200 €/an)  ";
    // var option3    =  (this.props.lang === "en") ? "PREMIUM (unlimited 2 users)" : "PREMIUM (illimité 2 pers.)";
 
      // return (
      //   // <select onChange={e => this.changeTestCount(e)} className="form-control fomt-control-lg"> used to manuplate the amount on changes
      //   <select onChange={e => this.changeTestCount(e)} className="form-control fomt-control-lg">
      //     <option>Please Select</option>
      //     <option value="1~20~1 Test">01</option>
      //     <option value="2~38~2 Test">02</option>
      //     <option value="3~54~3 Test">03</option>
      //     <option value="4~68~4 Test">04</option>
      //     <option value="1~120~DEBREIFING">{debreifingtest }</option>
      //     <option value="2~145~ENTREPRENEUR">{enterkilltest}</option>
      //     <option value="4~175~FULL SKILLS ">{fullskilltest}</option>
      //   </select>
      // )
      // 107.64
      return (
      <select onChange={e => this.changeTestCount2(e)} className="form-control fomt-control-lg">
        <option>Please Select</option>
        {localStorage.getItem('classic')?'':<option value="2~60~2 Test">{option1}</option>}
        <option value="1~390~1 Test">{option1}</option>
        <option value="12~4200~12 Test">{option2}</option>
      </select>
      )
  };

  renderRadioField = ({ input, label, type, checked, id }) => (
    <div>
      <input {...input} type={type} checked={checked} id={id} />
      <label>{label}</label>
    </div>
  );

  renderPayPalButton2= () => {
    let { tests,amount, currency,testname } = this.state;
    const token = localStorage.getItem("token");
    var userType = localStorage.getItem("role");
    var lang=localStorage.getItem('lang');
    console.log('tests => '+tests+' amount '+amount+'çurrency'+currency);

    if (amount == 420.00 && this.state.condition=='true') {
      return(

        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
  <input type="hidden" name="cmd" value="_s-xclick" />
  <input type="hidden" name="hosted_button_id" value="3VNP6AMHV4TWC" />
  <input type="hidden" name="currency_code" value="EUR" />
  <button className="btn btn-primary"  border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Subscribe" >Subscribe</button>
</form>
      )
    }
    
       if (amount == 468.00 && this.state.condition=='true') {
      return(

<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
  <input type="hidden" name="cmd" value="_s-xclick" />
  <input type="hidden" name="hosted_button_id" value="YMY3BC43YVULQ" />
  <input type="hidden" name="currency_code" value="EUR" />
  <button className="btn btn-primary"  border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Subscribe" >Subscribe</button>
</form>
      )
    }
    

  }

  promo(value){
    console.log(value)
    localStorage.removeItem("promos")
    this.setState({promo:value})
    console.log(this.state.promo)
    if(this.state.promo=="PRH5"){
      localStorage.setItem("promos",this.state.promo)
    }
    if(this.state.promo=="PRH10"){
      localStorage.setItem("promos",this.state.promo)
    }if(this.state.promo=="NOEL"){
      localStorage.setItem("promos",this.state.promo)
    }if(this.state.promo=="PRH20"){
      localStorage.setItem("promos",this.state.promo)
    }if(this.state.promo=="PRH50"){
      localStorage.setItem("promos",this.state.promo)
    }if(this.state.promo=="PRH100"){
      localStorage.setItem("promos",this.state.promo)
    }
  }
  NOEL(value){
    console.log(value)
    localStorage.removeItem("promos")
    this.setState({promo:value})
    console.log(this.state.promo)
  
  }
  promoMessage(){
    // setTimeout(() => {
    //   this.props.clearMessage();
    // }, 80000);
    // check for signup error message
    console.log("promos"+localStorage.getItem("promos"))
    if(localStorage.getItem("promos")=="NOEL"){
      return (
        <div  class=" alert alert-success alert-dismissible fade show "  role="alert">
        <FormattedHTMLMessage id="promomessage"/>
        </div>
      );
    }
  }
  renderPayPalButton= () => {
    let { tests,amount, currency,testname } = this.state;
    const token = localStorage.getItem("token");
    var userType = localStorage.getItem("role");
    var lang=localStorage.getItem('lang');
    console.log('tests => '+tests+' amount '+amount+'çurrency'+currency);

    if (amount > 0) {
      console.log("1"+localStorage.getItem("promos"))
        // if(localStorage.getItem("promos")&&localStorage.getItem("promos")=="NOEL" ){
        //  var  amounts=amount - (amount*0.30).toFixed(2)
        //  amounts = parseFloat(amounts.toFixed(2))
        //  console.log(amounts)
        // }else 
        if(localStorage.getItem("promos")&&localStorage.getItem("promos")=="PRH5" ){
          var  amounts=amount - (amount*0.05.toFixed(2))
          console.log(amounts)
         }else if(localStorage.getItem("promos")&&localStorage.getItem("promos")=="PRH10" ){
          var  amounts=amount - (amount*0.10.toFixed(2))
          console.log(amounts)
         }else if(localStorage.getItem("promos")&&localStorage.getItem("promos")=="PRH20" ){
          var  amounts=amount - (amount*0.20.toFixed(2))
          console.log(amounts)
         }else if(localStorage.getItem("promos")&&localStorage.getItem("promos")=="PRH50" ){
          var  amounts=amount - (amount*0.5.toFixed(2))
          console.log(amounts)
         }else if(localStorage.getItem("promos")&&localStorage.getItem("promos")=="PRH100" ){
          var  amounts=amount - (amount)
          console.log(amounts)
         }else{
          console.log("2")
          var amounts=amount;
          console.log(amounts)
        }
      }
        if (amount > 0 && this.state.condition=='true') {
// const confirmBox = window.confirm(  "Your total amount including 20% vat for "+tests+" tests = "+amount+" "+currency);
//     if(confirmBox===true){
      return (
        <PayPalButton
        amount= {amounts}
        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        shippingPreference="NO_SHIPPING"
        options={{
            clientId: PAYPAL_KEY,
            currency: currency
        }}
        onError ={(err) => {
          console.log(' onError ===> '+JSON.stringify(err));
          this.setState({
            error: true,
            errorMsg: err
          });
          this.setState({ loading: false });
        }}
        catchError ={(err) => {
          console.log(' onError ===> '+JSON.stringify(err));
          this.setState({
            error: true,
            errorMsg: err
          });
          this.setState({ loading: false });
        }}
        onSuccess={(details, data) => {
          this.setState({ loading: true }); // loading start
          //alert("Transaction completed by " + details.payer.name.given_name+' -- '+data.orderID);
          //console.log(' onSuccess ===> '+JSON.stringify(data)+''+' details ===> '+JSON.stringify(details));
            console.log(data);
            try {
              var OrderData = {
                amount: amount,
                tests: tests,
                testname: 'Subscription',
                userRole: userType,
                orderId : data.orderID,
                lang:lang
              };
              // console.log(lang);
              this.props.updaetPaymentData(token, OrderData, response => {
                //console.log(' errorsCode '+JSON.stringify(response.response.status));
                if(response.status === 200){
                  //console.log(' status1 ==> '+response.status+' status1 '+response.data.status);
                  if (response.data.status === "succeeded") {
                    this.setState({ complete: true });
                    // updat the remaintest data in state by fething updated record
                    var userid = localStorage.getItem("userId");
                    //console.log("user loggedIN "+userid);
                    var api_url = `${ROOT_URL}/getTests/${userid}`;
                    // get no of users test
                    axios.get(api_url, {
                      headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json"
                      }
                    }).then(response => {
                     console.log('Update remainingTest  '+response.data.tests);
                     //remainingTest
                     $('#remainingTest').html('('+response.data.tests+')');
                      this.setState({
                        remainingTest: response.data.tests
                      });
                    }).catch(error => {
                      console.log(error);
                    });
                  } else {
                    this.setState({
                      error: true,
                      errorMsg: "Error occur during payment"
                    });
                  }
                  this.setState({ loading: false });
                }else{
                  this.setState({
                    error: true,
                    errorMsg: response.response.status+" Error!"+response.response.data.message
                  });
                  this.setState({ loading: false });
                }
              });
            } catch (error) {
              console.log(' errors++++>> '+JSON.stringify(error));
              this.setState({
                error: true,
                errorMsg: error.message
              });
              this.setState({ loading: false });
            }
          // OPTIONAL: Call your server to save the transaction
          // return fetch("/paypal-transaction-complete", {
          //   method: "post",
          //   body: JSON.stringify({
          //     orderID: data.orderID
          //   })
          // });
        }}
      />
      )
    // }
    }
  };

  render() {
    let { complete,tests,testname } = this.state;
    var userType = localStorage.getItem("role");
    //console.log('userType => '+userType);
    var redirectTO = (userType === 'User' ? '/individual' :'/hr');
    //if (complete) return <h4 className="text-success text-center"><FormattedMessage id="buycredit.message" defaultMessage="Thank you for purchase test, Now you can continue do your test"/></h4>;
    if (complete) return <div><h4 className="text-success text-center"><FormattedMessage id="buycredit.message" defaultMessage={`Thank you for your purchase {nooftest} no of test, Now you can continue and do your test `} values={{nooftest: testname}}/></h4><div className="col-sm-12 mx-auto text-center mb-4 pb-45"><Link to={redirectTO} className="btn btn-warning mt-3 mx-3"><FormattedMessage id="choose_your_test_btn" defaultMessage="CHOOSE YOUR TEST "/></Link></div></div>;
    //var choose = <FormattedMessage id="buytest.choose" defaultMessage="Choose"/>
    //console.log(choose);
    return (
      <div className="card-div">
        <React.Fragment>{this.renderLoading()}</React.Fragment>
        <h2 className="text-center mb-4"><FormattedMessage id="buysub.title" defaultMessage="SUBSCRIBE"/></h2>
        {userType === 'User' ? (
          <h2 className="text-center mb-4"><FormattedMessage id="buytest.title.individual" defaultMessage="BUY TESTS FOR INDIVIDUAL"/></h2>
        ):(
          <h4 className="text-center mb-4"><FormattedMessage id="buysub.title2" defaultMessage="ANNUAL PAYMENT BY CREDIT CARD OR PAYPAL"/></h4>
        )}
        {this.renderError()}
        {/* {this.promoMessage()} */}
        {/* <form onSubmit={this.handleSubmit}> */}
        <form>
          <div className="form-group">
            <div className="form-check form-check-inline">
              <Field name="currency" component={this.renderRadioField} type="radio"  checked={(this.state.currency !== 'USD' ? true : false)} value="EUR" label="EUR" id="EUR" onChange={e => this.updateCurrency(e)} />
              <Field name="currency" component={this.renderRadioField} type="radio" checked={(this.state.currency === 'USD' ? true : false)} value="USD" id="USD" label="USD" onChange={e => this.updateCurrency(e)} />
            </div>
          </div>
          <div className="form-group">
            <label className="font-weight-bold">
            {userType === 'User' ? (
            <FormattedMessage id="inbuytest.noof.creits" defaultMessage="No. of credits"/>):(
              <FormattedMessage id="subbuy.noof.creits" defaultMessage="Choose your subcription:"/>)
            }
            </label>
              {this.renderSelectBox(userType)} 
          </div>
          <div className="form-group">
            <label className="font-weight-bold">
            Promo Code &nbsp;
            </label>
            <input  type="text" value={this.state.promo} onChange={(e) =>this.NOEL(e.target.value) } onBlur={(e) => this.promo(e.target.value)}/>
            <input type="button" value="Apply" className="btn btn-sm btn-primary ml-1 " onClick={this.promoMessage}/><br/>
            <FormattedMessage id="inbuytest.validity" defaultMessage="24h Validity "/>
          </div>

          <div className="form-group">
          <input type="checkbox" value='true' onChange={e=> this.onChecked(e)}/>
          <label className="font-weight-bold" htmlFor=""><FormattedHTMLMessage id="accept.subcondition" defaultMessage="&nbsp;I accept conditions of the subcription"/></label>
          </div>
          {/* <div className="form-group">
          <input type="checkbox" value='true' onChange={e=> this.onChecked2(e)}/>
          <label className="font-weight-bold" htmlFor=""><FormattedHTMLMessage id="accept.recuring.payment" defaultMessage="&nbsp;I accept conditions of the subcription"/></label>
          </div> */}
          {/* <div hidePostalCode={true} /> */}
          {/* <CardElement hidePostalCode={true} /> */}
          {/* <CardNumberElement />
        <CardExpiryElement />
        <CardCVCElement /> */}
          <div className="text-center">
          {this.renderPayPalButton()}
              {/* <button className="btn btn-info my-3">
                <FormattedMessage id="buytest.button" defaultMessage="BUY CREDITS"/>
              </button> */}
              <img src="assets/images/stripe_secure.png" alt="First slide" className="cc_secure" />
          </div>
        </form>
        <div className="buy-credits-detail">
       
        {localStorage.getItem('classic')?'':<FormattedHTMLMessage id="subscription.buy.detail2" defaultMessage="<ul className='indi-ul text-center'> <li>Buy 5 test credit = <b>€ 13,50</b>/test (-10%) €67,50</li><li>Buy 10 test credits = <b>€ 9,90</b>/test (-33%):€ 99</li><li>Buy 50 test credits = <b>€ 9</b>/test (-40%)   :€ 450</li></ul>"/>}

            <FormattedHTMLMessage id="subscription.buy.detail" defaultMessage="<ul className='indi-ul text-center'> <li>Buy 5 test credit = <b>€ 13,50</b>/test (-10%) €67,50</li><li>Buy 10 test credits = <b>€ 9,90</b>/test (-33%):€ 99</li><li>Buy 50 test credits = <b>€ 9</b>/test (-40%)   :€ 450</li></ul>"/>
    
         
            <ul className='indi-ul font-weight-bold'> <li> <FormattedMessage id="hrbuytest.nooftest" defaultMessage="Total no of remaining test you have "/>( {this.state.remainingTest} ) </li></ul> 

          
          
           {/* {userType === 'User' ? (
            <div className="col-sm-12 mx-auto text-center mb-4 pb-45"><Link to={redirectTO} className="btn btn-warning mt-3 mx-3"><FormattedMessage id="ind.start.ur.test" defaultMessage="Start your test"/></Link></div>
          ):(
            <div className="col-sm-12 mx-auto text-center mb-4 pb-45"><Link to={redirectTO} className="btn btn-warning mt-3 mx-3"><FormattedMessage id="hr.start.ur.test" defaultMessage="Access to your tools"/></Link></div>
          )}  */}
        </div>
        {/* <div>{this.renderLoading()}</div> */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    lang: state.locale.lang
  };
};


CheckoutForm = connect(
  mapStateToProps,
  { updaetPaymentData }
)(CheckoutForm);

export default reduxForm({
  form: "CheckoutForm"
})(CheckoutForm);

// export default connect(
//   mapStateToProps,
//   { makeCharge }
// )(injectStripe(CheckoutForm));
